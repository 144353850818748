<template>
  <div @scroll="isBottom" id="list-bill">
    <h2 class="mb-5">Riwayat Transaksi Pembayaran Tempo</h2>
    <div class="d-flex col pr-0 justify-content-between mb-3">
      <div class="d-flex container-button-type gap-12 justify-content-between align-items-center mb-3">
        <div v-for="({ title, key }) in listTab" :key="key">
          <div v-if="key === activeKey" class="btn active flex ml-1 w-auto" @click="changeType(key)">{{ title }}</div>
          <div v-else class="btn inactive flex ml-1 w-auto" @click="changeType(key)">{{ title }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex pr-0 justify-content-center align-items-center flex-column pt-5"
      v-if="!loading && data.length === 0">
      <div v-if="activeKey === 0" class="mt-3 ml-2 h4 align-items-center d-flex flex-column">
        <div class="d-flex align-items-center mb-4">
          <EmptyIcon />
        </div>
        <span style="color: #015CA1" class="ml-2 desc-empty-state">Anda belum memiliki transaksi. Yuk, mulai belanja</span>
        <div class="card-footer border-top-0 mt-1">
          <button @click="goToHome" class="btn w-100 button-empty-state">Belanja
            Sekarang</button>
        </div>
      </div>
      <div v-else class="w-35 d-flex align-items-center flex-column pb-5">
        <div class="d-flex align-items-center">
          <EmptyIcon />
        </div>
        <span style="color: #015CA1" class="ml-2 h4 mt-3 desc-empty-filter">Tidak ada transaksi yang sesuai
          filter</span>
      </div>
    </div>
    <div v-else id="wrap" class="col pr-0"
      v-for="({ payment_number, method, paymentImage, firstItem, theRestItem, status, created_at, newTotal, payment_id, title, account_number }, index) in data"
      :key="index">
      <div class="card col-12 d-flex justify-content-center">
        <div class="card-header d-flex justify-content-between align-items-center">
          <div class="d-flex align-items-center">
            <div class="mr-2">
              <BlueWalletIcon />
            </div>
            <div class="flex-column d-flex">
              <span style="color: #929292;">Nomor Order</span>
              <span>{{ payment_number }}</span>
            </div>
          </div>
          <div class="btn status text-white tag-status" :style="{ background: bgColor[status], height: '35px' }">
            {{ title }}
          </div>
        </div>
        <div class="card-body d-flex justify-content-between align-items-end">
          <div class="d-flex">
            <div class="mr-2">
              <img :src="paymentImage" :alt="paymentImage" width="114.17px" />
            </div>
            <div class="d-flex flex-column">
              <span>{{ method && method.payment_name }}</span>
              <span style="color: #929292;">{{ account_number || '' }}</span>
            </div>
          </div>
          <div>
            Total Pembayaran : <span class="h5" style="color: #F5831F;">Rp. {{ newTotal }}</span>
          </div>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button v-if="status === 'DELIVERED'" disabled style="color: #ABABAB; border: 1px solid #ABABAB"
            class="btn disabled action-button" @click.stop="() => undefined">
            <span>
              <i class="fe fe-clock"></i>
            </span>
            <span>
              Menunggu verifikasi
            </span>
          </button>
          <span
            style="white; border: 1px solid #015289; color: #015CA1;"
            class="btn ml-3 w-auto action-button"
            @click.stop="$router.push({ path: `/account/detail-bill-transaction/${payment_id}`})">
            Detail Transaksi
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */

import { mapState } from 'vuex'
import moment from 'moment'
import BlueWalletIcon from '@/components/Icons/BlueWallet.vue'
import EmptyIcon from '@/components/Icons/Empty.vue'

export default {
  // name: 'component-purchase-index',
  components: {
    EmptyIcon,
    BlueWalletIcon,
  },
  data: function () {
    return {
      bgColor: {
        PAID: '#015CA1',
        CANCELLED: '#FF0000',
        REJECTED: '#FF0000',
        ACCEPTED: '#929292',
        UNPAID: '#FA8F8F',
      },
      listTab: [
        {
          title: 'Semua',
          value: 'all',
          state: ['ALL'],
          key: 0,
        },
        {
          title: 'Menunggu Pembayaran',
          value: 'unpaid',
          state: ['UNPAID'],
          key: 1,
        },
        {
          title: 'Revisi',
          value: 'revisi',
          state: ['ACCEPTED'],
          key: 2,
        },
        {
          title: 'Sudah Dibayar',
          value: 'paid',
          state: ['PAID'],
          key: 3,
        },
        {
          title: 'Dibatalkan',
          value: 'cancelled',
          state: ['CANCELLED'],
          key: 5,
        },
      ],
      data: [],
      page: 1,
      loading: false,
      activeKey: 0,
      confirmedStatusById: '',
      total_waiting_payment: 0,
      limit: 20,
      total: 0,
    }
  },
  computed: {
    ...mapState(['purchase', 'apps',]),
  },
  methods: {
    changeType(key) {
      this.activeKey = key
      this.page = 1
      this.limit = 20
      const getValueByKey = this.listTab.find(item => item.key === key)
      this.fetchSettlementPayment(getValueByKey?.value, false)
    },
    goToHome() {
      this.$router.push('/dashboard')
    },
    showDetail(param) {
      this.$router.push({ path: `/purchase/order/${param}` })
    },
    fetchSettlementPayment(status, moreFetch) {
      this.loading = true
      this.$store.dispatch('billing/GETLISTSETTLEMENTPAYMENTHISTORY', {
        page: this.page,
        limit: this.limit,
        channel_id: this.$store.state.user.user_data.channel_id,
        customer_id: this.$store.state.user.user_data.buyer_id,
        status,
      })
        .then(({ data, total_record, limit }) => {
          const newData = data?.contents?.map(({ created_at, ...rest }) => ({
            ...rest,
            created_at: moment(created_at).format('DD MMMM YYYY'),
            // firstItem: rest.items[0],
            // theRestItem: rest.items && rest.items.length - 1 || '',
            paymentImage: rest.method && rest.method.image ? rest.method.image : null,
            newTotal: Intl.NumberFormat('en-US').format(rest.total_amount),
            title: this.listTab.find(item => item.state.includes(rest.status.toUpperCase()))?.title,
          }))
          const totalRestData = total_record - (data || []).length
          this.data = moreFetch ? [...newData, ...this.data] : newData
          this.loading = false
          this.page = this.page + 1
          this.total = total_record
          this.limit = totalRestData < 20 ? totalRestData : limit
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
        })
    },
    isBottom() {
      const el = document.querySelectorAll("#wrap")
      const navbarHeight = 151;
      const offsetElement = 5
      const lastEl = el[el.length - offsetElement]
      const bounds = lastEl.getBoundingClientRect().y
      const alreadyGetAllData = this.data.length >= this.total
      if (!this.loading && !alreadyGetAllData && bounds < navbarHeight) {
        const getValueByKey = this.listTab.find(({ key }) => key === this.activeKey)
        this.fetchSettlementPayment(getValueByKey?.value, true)
      }
    },
  },
  mounted() {
    const getValueByKey = this.listTab.find(({ key }) => key === 0)
    this.fetchSettlementPayment(getValueByKey?.value, false)
    window.addEventListener("scroll", this.isBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.isBottom);
  },
}
</script>

<style>
.active {
  background: #E8F6FE;
  border: 1px solid #015CA1 !important;
  border-radius: 12px;
  color: #015CA1;
}

.inactive {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 12px;
  color: #929292;
}

.customBox {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.status:hover {
  cursor: auto !important;
}

.thumbnail {
  cursor: pointer;
}

.container-button-type {
  overflow-x: hidden;
  overflow: hidden;
  ;
}

.action-button {
  border-radius: 8px;
  padding: 5px 40px;
}

.status-tag {
  border-radius: 16px;
  font-size: 14px;
  padding: 5px 20px;
}

.desc-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.desc-empty-filter {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
}

.button-empty-state {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  letter-spacing: 0em;
  padding: 10px 60px;
  color: #015CA1;
  border: 2.25px solid #015289;
  border-radius: 6.75px;
}

.tag-status {
  border-radius: 16px;
  padding: 5px 12px;
  font-size: 14px;
}
</style>
